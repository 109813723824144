import type { UspAnimation } from "@kippie/ui";
import type {
	IconLabelStoryblok,
	IconLinkStoryblok,
	LinkStoryblok,
	SocialLinkStoryblok,
	ImageLinkStoryblok,
	AssetStoryblok
} from "~/types/storyblok";
import type { LinkGroupStoryblok } from "~/types/storyblok-generic";

export interface UpsItemStoryblok {
	label: string;
}

interface UpsBarStoryblok {
	animationTime: string;
	animationType: UspAnimation;
	items: UpsItemStoryblok[];
	left: LinkStoryblok[];
	right: (IconLabelStoryblok | IconLinkStoryblok)[];
}

interface NavBarStoryblok {
	logo: AssetStoryblok;
	links: IconLinkStoryblok[];
	sidebarLinks: LinkStoryblok[];
	smallLinks: LinkStoryblok[];
	ctaLinks: LinkStoryblok[];
	paymentMethods: AssetStoryblok[];
	searchPlaceholder: string;
	socials: SocialLinkStoryblok[];
	imageLinks: ImageLinkStoryblok[];
}

interface NewsLetterStoryblok {
	buttonLabel: string;
	placeholder: string;
	text: string;
	title: string;
}

interface FooterStoryblok {
	title: string;
	copyright: string;
	rightsReserved: string;
	policies: LinkStoryblok[];
	linkGroups: LinkGroupStoryblok[];
	socials: SocialLinkStoryblok[];
}

interface ThemeStoryblok {
	usps: UpsBarStoryblok;
	navigation: NavBarStoryblok;
	newsletter: NewsLetterStoryblok;
	footer: FooterStoryblok;
}

export default async function useStoryblokTheme(theme: string, relations = []): Promise<ThemeStoryblok> {
	const storyblokApi = useStoryblokApi();

	const { data: requestData } = await storyblokApi.get("cdn/stories", {
		version: process.env.NODE_ENV === "production" ? "published" : "draft",
		content_type: "theme",
		resolve_relations: relations,
		starts_with: `layouts/${theme}`
	});

	if (requestData.stories.length) {
		const { usps, navbar, newsletter, footer } = requestData.stories[0].content;

		return {
			...(usps.length && { usps: usps[0] }),
			...(navbar.length && { navigation: navbar[0] }),
			...(newsletter.length && { newsletter: newsletter[0] }),
			...(footer.length && { footer: footer[0] })
		};
	} else {
		throw createError({ statusCode: 404, message: "Storyblok theme not found" });
	}
}
